<template>
  <div>
    <Loader />
    <section>
      <navbar></navbar>
        <div class="row no-gutters">
          <div class="col-md-12 col-sm-12">
            <router-view></router-view>
          </div>         
        </div>
        <FooterStyle1 class="iq-footer">
      <template v-slot:left>
          <p class="m-0 font-size-12">© Copyright 2021 <a href="https://womenfirstdigital.org/" target="_blank">Womenfirstdigital.org</a> All Rights Reserved.</p>
      </template>
    </FooterStyle1>
     </section>
  </div>
</template>
<script>
import Loader from '../../components/socialvue/loader/Loader'
import Navbar from '../../components/socialvue/navbars/NavBarStyle3'
import { socialvue } from '../../config/pluginInit'
import FooterStyle1 from '../../components/socialvue/footer/FooterStyle1'
export default {
  name: 'AuthLayout2',
  components: {
    Loader,Navbar,FooterStyle1
  },
  mounted () {
    socialvue.index()
  },
  data () {
    return {
      slickOptions: {
        slidesToShow: 1,
        arrows: false
      },
      // logo: logo,
      // bgImageURL: bgImage
    }
  }
}
</script>
